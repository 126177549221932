<template>

	<div class="page" :style="{'background-color': style.bgColor, backgroundImage: `url(${style.officialAccountBgImg})`}">
		<div class="container">
			<div class="notes" :style="{color: style.officialAccountBgImg ? '#fff' : color}">
				<h1>最后一步！</h1>
			</div>
			<div class="content radius" :style="{backgroundColor: style.officialAccountBgImg ? '#fff' : style.btnColor}">
				<div class="tips" :style="{color: style.officialAccountBgImg ? color : '#fff'}">
					长按识别二维码<br/>
					<!-- 获取专属入场凭证 -->
					进入公众号获取入场凭证
				</div>
				<div class="code radius">
					<img :src="qrcodeImg" alt="" class="fill">
				</div>
			</div>
		</div>
		<div class="footer">
			<bottom :url="style.officialAccountBgImg ? style.whiteFootImg : style.footImg"></bottom>
		</div>
  </div>
</template>

<script>
	import {
		sendTemplateMsg
	} from '@/api/index.js';
	
	import commonMixin from '@/view/process/mixin/common.js';
	
	const onLineImg = 'https://szcw-oss.oss-cn-shenzhen.aliyuncs.com/upload/store/public_qrcode.jpg';
	const testImg = 'https://szcw-oss.oss-cn-shenzhen.aliyuncs.com/upload/store/public_qrcode_desai.jpg';

	export default {
		mixins: [commonMixin],
    data() {
      return {
				// 根据服务器环境( 正式服务器、测试服务器)切换不同的二维码图片
				qrcodeImg: location.host.includes('test') ? testImg : onLineImg,
				color: '#6D6D6D', // #9F9D9D
      }
    },
    created() {
			this.setLanguge('zh'); // 设置语言环境
      this.sendMsg();
    },
    methods: {
			// 发送公众号模版消息
			sendMsg() {
				sendTemplateMsg(this.unionid).then(res => {
					if (res.code == 200) this.pageSwitch(res.data)
				})
			}
    },
  }
</script>

<style lang="less" scoped>

	.notes {
		width: 250px;
		margin: 8vh auto 6vh;

		h1 {
			text-align: center;
			font-size: 24px;
			margin-bottom: 16px;
		}

	}

	.content {
		width: 280px;
		padding: 40px 0 80px;
		margin: 0 auto 40px;
		
		.tips {
			text-align: center;
			font-size: 18px;
			line-height: 30px;
			margin: 0 auto 30px;
		}
		
		.code {
			width: 180px;
			height: 180px;
			margin: 0 auto;
			background-color: #fff;
		}
	}
</style>
